/* You can add global styles to this file, and also import other style files */
.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.font-35 {
  font-size: 35px !important;
}

.font-25 {
  font-size: 25px !important;
}

.pointer {
  cursor: pointer;
}

.pac-container {
  z-index: 1055;
}

/* Footer styles */
.footer {
  position: relative;
  z-index: 100;
}

/* Ensure content pages have proper spacing for footer */
.content-page {
  padding-bottom: 60px;
}

/* Login and signup pages need special handling */
.account-pages {
  padding-bottom: 60px;
}

/* Mobile optimizations */
@media (max-width: 767.98px) {
  .content-page {
    padding: 0px 0.5rem 60px !important;
  }
  
  .container-fluid {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  
  .card {
    margin-bottom: 1rem;
  }
  
  h1, .h1 {
    font-size: 1.75rem;
  }
  
  h2, .h2 {
    font-size: 1.5rem;
  }
  
  h3, .h3 {
    font-size: 1.25rem;
  }
  
  .btn {
    padding: 0.375rem 0.75rem;
  }
  
  .table-responsive {
    border-radius: 0.25rem;
  }
}

/* Improved sidebar for mobile */
@media (max-width: 991.98px) {
  .leftside-menu {
    width: 260px;
    left: -260px;
    transition: all 0.2s ease-in-out;
  }
  
  .sidebar-enable .leftside-menu {
    left: 0;
  }
  
  .sidebar-enable .wrapper {
    padding-left: 0 !important;
  }
  
  .sidebar-enable:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
}

@media (min-width: 576px) {
  .dropdown-lg {
      width: fit-content;
  }
}
